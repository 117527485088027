/* tslint:disable */
/* eslint-disable */
/**
 * Neural Payments App API
 * API documentation for Neural Payments API.  This API is a loose collection of endpoints that service small or unrelated concerns.
 *
 * The version of the OpenAPI document: 0.5.5
 * Contact: engineering@neuralpayments.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from "./base";

/**
 *
 * @export
 * @interface ExternalTransferDetails
 */
export interface ExternalTransferDetails {
  /**
   * Sender\'s name
   * @type {string}
   * @memberof ExternalTransferDetails
   */
  sender: string;
  /**
   * Amount to be transferred
   * @type {string}
   * @memberof ExternalTransferDetails
   */
  amount: string;
  /**
   * Description of transfer
   * @type {string}
   * @memberof ExternalTransferDetails
   */
  note: string;
  /**
   * Type of recipient identifier (e.g. phone, email)
   * @type {string}
   * @memberof ExternalTransferDetails
   */
  recipient_type: string;
  /**
   * Recipient contact information (e.g. phone, email)
   * @type {string}
   * @memberof ExternalTransferDetails
   */
  recipient_identifier: string;
  /**
   * Service that processed the payment (e.g. paypal, venmo)
   * @type {string}
   * @memberof ExternalTransferDetails
   */
  payment_type: string;
  /**
   * Current state of the transfer
   * @type {string}
   * @memberof ExternalTransferDetails
   */
  transfer_state: string;
  /**
   * DateTime when transfer state was most recently updated
   * @type {string}
   * @memberof ExternalTransferDetails
   */
  transfer_state_date: string;
}
/**
 *
 * @export
 * @interface ExternalTransferPaymentDetails
 */
export interface ExternalTransferPaymentDetails {
  /**
   * Payment type (\'paypal\', \'venmo\')
   * @type {string}
   * @memberof ExternalTransferPaymentDetails
   */
  payment_type: string;
  /**
   * Type of recipient identifier (e.g. phone, email)
   * @type {string}
   * @memberof ExternalTransferPaymentDetails
   */
  recipient_type?: string;
  /**
   * Recipient contact information (e.g. phone, email)
   * @type {string}
   * @memberof ExternalTransferPaymentDetails
   */
  recipient_identifier?: string;
}
/**
 *
 * @export
 * @interface FiUiCustomization
 */
export interface FiUiCustomization {
  /**
   * FI logo bg color
   * @type {string}
   * @memberof FiUiCustomization
   */
  logo_bg: string;
  /**
   * FI logo height
   * @type {string}
   * @memberof FiUiCustomization
   */
  logo_height: string;
  /**
   * FI logo width
   * @type {string}
   * @memberof FiUiCustomization
   */
  logo_width: string;
  /**
   * FI logo url
   * @type {string}
   * @memberof FiUiCustomization
   */
  logo_url: string;
  /**
   * FI receiving app primary bg color
   * @type {string}
   * @memberof FiUiCustomization
   */
  off_network_bg_primary: string;
  /**
   * FI receiving app secondary bg color
   * @type {string}
   * @memberof FiUiCustomization
   */
  off_network_bg_secondary: string;
  /**
   * FI receiving app button text and border color
   * @type {string}
   * @memberof FiUiCustomization
   */
  off_network_button_color: string;
  /**
   * FI receiving app info text and border color
   * @type {string}
   * @memberof FiUiCustomization
   */
  off_network_text_color: string;
  /**
   * FI receiving app ribbon left color
   * @type {string}
   * @memberof FiUiCustomization
   */
  ribbon_color_1: string;
  /**
   * FI receiving app ribbon right color
   * @type {string}
   * @memberof FiUiCustomization
   */
  ribbon_color_2: string;
  /**
   * FI logo vertical margin
   * @type {string}
   * @memberof FiUiCustomization
   */
  logo_vertical_margin: string;
  /**
   * FI advertising link
   * @type {string}
   * @memberof FiUiCustomization
   */
  advertising_link: string;
  /**
   * FI advertising text
   * @type {string}
   * @memberof FiUiCustomization
   */
  advertising_text: string;
  /**
   *
   * @type {FiUiCustomizationButtonIndexes}
   * @memberof FiUiCustomization
   */
  button_indexes: FiUiCustomizationButtonIndexes;
  /**
   *
   * @type {FiUiCustomizationPrizeoutPartner}
   * @memberof FiUiCustomization
   */
  prizeout_partner: FiUiCustomizationPrizeoutPartner;
}
/**
 * Button placement and ordering
 * @export
 * @interface FiUiCustomizationButtonIndexes
 */
export interface FiUiCustomizationButtonIndexes {
  /**
   * paypal button index
   * @type {number}
   * @memberof FiUiCustomizationButtonIndexes
   */
  paypal_index: number;
  /**
   * venmo button index
   * @type {number}
   * @memberof FiUiCustomizationButtonIndexes
   */
  venmo_index: number;
  /**
   * p2c button index
   * @type {number}
   * @memberof FiUiCustomizationButtonIndexes
   */
  p2c_index: number;
  /**
   * p2c button index
   * @type {number}
   * @memberof FiUiCustomizationButtonIndexes
   */
  p2a_index: number;
  /**
   * prizeout button index
   * @type {number}
   * @memberof FiUiCustomizationButtonIndexes
   */
  prizeout_index: number;
  /**
   * should display prizeout_widget
   * @type {boolean}
   * @memberof FiUiCustomizationButtonIndexes
   */
  prizeout_widget: boolean;
  /**
   * decline button index
   * @type {number}
   * @memberof FiUiCustomizationButtonIndexes
   */
  decline_index: number;
}
/**
 * Prizeout config data
 * @export
 * @interface FiUiCustomizationPrizeoutPartner
 */
export interface FiUiCustomizationPrizeoutPartner {
  /**
   * prizeout partner id
   * @type {string}
   * @memberof FiUiCustomizationPrizeoutPartner
   */
  partner_id: string;
  /**
   * prizeout environment
   * @type {string}
   * @memberof FiUiCustomizationPrizeoutPartner
   */
  environment: string;
}
/**
 *
 * @export
 * @interface GetExternalTransfer404Response
 */
export interface GetExternalTransfer404Response {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof GetExternalTransfer404Response
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof GetExternalTransfer404Response
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof GetExternalTransfer404Response
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof GetExternalTransfer404Response
   */
  detail: string;
}
/**
 *
 * @export
 * @interface GetExternalTransfer500Response
 */
export interface GetExternalTransfer500Response {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof GetExternalTransfer500Response
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof GetExternalTransfer500Response
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof GetExternalTransfer500Response
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof GetExternalTransfer500Response
   */
  detail: string;
}
/**
 *
 * @export
 * @interface GetUi401Response
 */
export interface GetUi401Response {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof GetUi401Response
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof GetUi401Response
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof GetUi401Response
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof GetUi401Response
   */
  detail: string;
}
/**
 *
 * @export
 * @interface TransferDetails
 */
export interface TransferDetails {
  /**
   * fully qualified URL of the resource
   * @type {string}
   * @memberof TransferDetails
   */
  path: string;
}

/**
 * BrandingApi - axios parameter creator
 * @export
 */
export const BrandingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get a branding resource for a transfer
     * @param {string} transferId The unique id of the transfer
     * @param {string} key The identifying key name of the branding resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferResource: async (
      transferId: string,
      key: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transferId' is not null or undefined
      assertParamExists("getTransferResource", "transferId", transferId);
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getTransferResource", "key", key);
      const localVarPath = `/branding/transfer/{transfer_id}`.replace(
        `{${"transfer_id"}}`,
        encodeURIComponent(String(transferId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (key !== undefined) {
        localVarQueryParameter["key"] = key;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BrandingApi - functional programming interface
 * @export
 */
export const BrandingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BrandingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get a branding resource for a transfer
     * @param {string} transferId The unique id of the transfer
     * @param {string} key The identifying key name of the branding resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransferResource(
      transferId: string,
      key: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferDetails>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransferResource(transferId, key, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap["BrandingApi.getTransferResource"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * BrandingApi - factory interface
 * @export
 */
export const BrandingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = BrandingApiFp(configuration);
  return {
    /**
     *
     * @summary Get a branding resource for a transfer
     * @param {string} transferId The unique id of the transfer
     * @param {string} key The identifying key name of the branding resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferResource(transferId: string, key: string, options?: any): AxiosPromise<TransferDetails> {
      return localVarFp.getTransferResource(transferId, key, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * BrandingApi - object-oriented interface
 * @export
 * @class BrandingApi
 * @extends {BaseAPI}
 */
export class BrandingApi extends BaseAPI {
  /**
   *
   * @summary Get a branding resource for a transfer
   * @param {string} transferId The unique id of the transfer
   * @param {string} key The identifying key name of the branding resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandingApi
   */
  public getTransferResource(transferId: string, key: string, options?: RawAxiosRequestConfig) {
    return BrandingApiFp(this.configuration)
      .getTransferResource(transferId, key, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExternalApi - axios parameter creator
 * @export
 */
export const ExternalApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Cancel payment for external transfer
     * @param {string} transferId The unique id of the external transfer
     * @param {string} inviteId The unique id of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalTransfer: async (
      transferId: string,
      inviteId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transferId' is not null or undefined
      assertParamExists("deleteExternalTransfer", "transferId", transferId);
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("deleteExternalTransfer", "inviteId", inviteId);
      const localVarPath = `/external/transfer/{transfer_id}/{invite_id}`
        .replace(`{${"transfer_id"}}`, encodeURIComponent(String(transferId)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get details to setup external transfer
     * @param {string} transferId The unique id of the external transfer
     * @param {string} inviteId The unique id of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalTransfer: async (
      transferId: string,
      inviteId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transferId' is not null or undefined
      assertParamExists("getExternalTransfer", "transferId", transferId);
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("getExternalTransfer", "inviteId", inviteId);
      const localVarPath = `/external/transfer/{transfer_id}/{invite_id}`
        .replace(`{${"transfer_id"}}`, encodeURIComponent(String(transferId)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post payment details for external transfer
     * @param {string} transferId The unique id of the external transfer
     * @param {string} inviteId The unique id of the invite
     * @param {ExternalTransferPaymentDetails} externalTransferPaymentDetails Request Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postExternalTransfer: async (
      transferId: string,
      inviteId: string,
      externalTransferPaymentDetails: ExternalTransferPaymentDetails,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transferId' is not null or undefined
      assertParamExists("postExternalTransfer", "transferId", transferId);
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("postExternalTransfer", "inviteId", inviteId);
      // verify required parameter 'externalTransferPaymentDetails' is not null or undefined
      assertParamExists("postExternalTransfer", "externalTransferPaymentDetails", externalTransferPaymentDetails);
      const localVarPath = `/external/transfer/{transfer_id}/{invite_id}`
        .replace(`{${"transfer_id"}}`, encodeURIComponent(String(transferId)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalTransferPaymentDetails,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExternalApi - functional programming interface
 * @export
 */
export const ExternalApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExternalApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Cancel payment for external transfer
     * @param {string} transferId The unique id of the external transfer
     * @param {string} inviteId The unique id of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteExternalTransfer(
      transferId: string,
      inviteId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExternalTransfer(transferId, inviteId, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap["ExternalApi.deleteExternalTransfer"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get details to setup external transfer
     * @param {string} transferId The unique id of the external transfer
     * @param {string} inviteId The unique id of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExternalTransfer(
      transferId: string,
      inviteId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalTransferDetails>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalTransfer(transferId, inviteId, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap["ExternalApi.getExternalTransfer"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Post payment details for external transfer
     * @param {string} transferId The unique id of the external transfer
     * @param {string} inviteId The unique id of the invite
     * @param {ExternalTransferPaymentDetails} externalTransferPaymentDetails Request Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postExternalTransfer(
      transferId: string,
      inviteId: string,
      externalTransferPaymentDetails: ExternalTransferPaymentDetails,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postExternalTransfer(
        transferId,
        inviteId,
        externalTransferPaymentDetails,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap["ExternalApi.postExternalTransfer"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * ExternalApi - factory interface
 * @export
 */
export const ExternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ExternalApiFp(configuration);
  return {
    /**
     *
     * @summary Cancel payment for external transfer
     * @param {string} transferId The unique id of the external transfer
     * @param {string} inviteId The unique id of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalTransfer(transferId: string, inviteId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteExternalTransfer(transferId, inviteId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get details to setup external transfer
     * @param {string} transferId The unique id of the external transfer
     * @param {string} inviteId The unique id of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalTransfer(transferId: string, inviteId: string, options?: any): AxiosPromise<ExternalTransferDetails> {
      return localVarFp.getExternalTransfer(transferId, inviteId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post payment details for external transfer
     * @param {string} transferId The unique id of the external transfer
     * @param {string} inviteId The unique id of the invite
     * @param {ExternalTransferPaymentDetails} externalTransferPaymentDetails Request Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postExternalTransfer(
      transferId: string,
      inviteId: string,
      externalTransferPaymentDetails: ExternalTransferPaymentDetails,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postExternalTransfer(transferId, inviteId, externalTransferPaymentDetails, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ExternalApi - object-oriented interface
 * @export
 * @class ExternalApi
 * @extends {BaseAPI}
 */
export class ExternalApi extends BaseAPI {
  /**
   *
   * @summary Cancel payment for external transfer
   * @param {string} transferId The unique id of the external transfer
   * @param {string} inviteId The unique id of the invite
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalApi
   */
  public deleteExternalTransfer(transferId: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return ExternalApiFp(this.configuration)
      .deleteExternalTransfer(transferId, inviteId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get details to setup external transfer
   * @param {string} transferId The unique id of the external transfer
   * @param {string} inviteId The unique id of the invite
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalApi
   */
  public getExternalTransfer(transferId: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return ExternalApiFp(this.configuration)
      .getExternalTransfer(transferId, inviteId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post payment details for external transfer
   * @param {string} transferId The unique id of the external transfer
   * @param {string} inviteId The unique id of the invite
   * @param {ExternalTransferPaymentDetails} externalTransferPaymentDetails Request Payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalApi
   */
  public postExternalTransfer(
    transferId: string,
    inviteId: string,
    externalTransferPaymentDetails: ExternalTransferPaymentDetails,
    options?: RawAxiosRequestConfig,
  ) {
    return ExternalApiFp(this.configuration)
      .postExternalTransfer(transferId, inviteId, externalTransferPaymentDetails, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FiUiCustomizationApi - axios parameter creator
 * @export
 */
export const FiUiCustomizationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get fi ui customization for a transfer
     * @param {string} transferId The unique id of the transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUi: async (transferId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transferId' is not null or undefined
      assertParamExists("getUi", "transferId", transferId);
      const localVarPath = `/branding/transfer/ui/{transfer_id}`.replace(
        `{${"transfer_id"}}`,
        encodeURIComponent(String(transferId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FiUiCustomizationApi - functional programming interface
 * @export
 */
export const FiUiCustomizationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FiUiCustomizationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get fi ui customization for a transfer
     * @param {string} transferId The unique id of the transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUi(
      transferId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiUiCustomization>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUi(transferId, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap["FiUiCustomizationApi.getUi"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * FiUiCustomizationApi - factory interface
 * @export
 */
export const FiUiCustomizationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FiUiCustomizationApiFp(configuration);
  return {
    /**
     *
     * @summary Get fi ui customization for a transfer
     * @param {string} transferId The unique id of the transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUi(transferId: string, options?: any): AxiosPromise<FiUiCustomization> {
      return localVarFp.getUi(transferId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * FiUiCustomizationApi - object-oriented interface
 * @export
 * @class FiUiCustomizationApi
 * @extends {BaseAPI}
 */
export class FiUiCustomizationApi extends BaseAPI {
  /**
   *
   * @summary Get fi ui customization for a transfer
   * @param {string} transferId The unique id of the transfer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiUiCustomizationApi
   */
  public getUi(transferId: string, options?: RawAxiosRequestConfig) {
    return FiUiCustomizationApiFp(this.configuration)
      .getUi(transferId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
