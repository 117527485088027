import React, { useContext, useEffect, useState } from "react";
import { Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import phone from "phone";
import queryString from "query-string";
import ExternalTransferContext from "src/stores/ExternalTransferStore";
import { WrappedCardInput } from "./WrappedCardInput";
import GlobalTheme from "src/theme";

const textFieldStyle = {
  width: "95%",
  margin: "0px auto",
};

export const TransferWorkflow = observer(() => {
  const defaultRecipientType: string = "";
  const defaultRecipientIdentifier: string = "";
  const { fi_ui_customization } = GlobalTheme();

  const location = window.location.href.split("?")[1];

  const params = queryString.parse(location);
  const transferId = params.t as string;
  const inviteId = params.i as string;

  const externalTransferStore = useContext(ExternalTransferContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorBankAccount, setErrorBankAccount] = useState(false);
  const [errorBankRouting, setErrorBankRouting] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  const [recipientType, setRecipientType] = useState(defaultRecipientType);
  const [recipientIdentifier, setRecipientIdentifier] = useState(defaultRecipientIdentifier);
  const [recipientAccountDetails, setRecipientAccountDetails] = useState({ routing_number: "", account_number: "" });
  const [statusDate, setStatusDate] = useState(new Date());
  const [viewState, setViewState] = useState("");
  const [frameHeight, setFrameHeight] = useState("525px");

  const handleRecipientIdentifierChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setRecipientIdentifier(event.target.value ? event.target.value : "");
  };

  interface AccountFields {
    target: {
      id: string;
      value: string;
    };
  }

  const handleRecipientAccountDetailsChange = ({ target: { id, value } }: AccountFields) => {
    switch (id) {
      case "routing_number":
        {
          if (value.length > 12) {
            return;
          } else {
            setRecipientAccountDetails({ ...recipientAccountDetails, [id]: value });
          }
        }
        break;
      case "account_number":
        {
          if (value.length > 17) {
            return;
          } else {
            setRecipientAccountDetails({ ...recipientAccountDetails, [id]: value });
          }
        }
        break;
      default: {
        setRecipientAccountDetails({ ...recipientAccountDetails, [id]: value });
      }
    }
  };

  function returnHome() {
    setErrorEmail(false);
    setLoading(false);
    setViewState("default");
  }

  function guessRecipientType(test: string) {
    if (paymentType === "pushtoaccount") {
      return "routing_account";
    } else {
      return test.includes("@") ? "email" : "phone";
    }
  }

  function confirmIdentifier() {
    if (!recipientIdentifier || 0 === recipientIdentifier.length) {
      return true;
    }

    const ptype = guessRecipientType(recipientIdentifier);
    setRecipientType(ptype);

    if ("venmo" === paymentType && "email" === ptype) {
      setErrorEmail(true);
      setLoading(false);
      return false;
    }

    if ("phone" === ptype) {
      const parsed = phone(recipientIdentifier, { country: "USA" });
      if (!parsed.isValid) {
        setErrorEmail(true);
        setLoading(false);
        return false;
      }
      setRecipientIdentifier(parsed.phoneNumber);
    }

    if ("email" === ptype) {
      const e =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!e.test(recipientIdentifier.toLowerCase())) {
        setErrorEmail(true);
        setLoading(false);
        return false;
      }
    }

    setErrorEmail(false);
    setLoading(false);
    return true;
  }

  const confirmBankAccount = () => {
    if (recipientAccountDetails.routing_number.length < 9) {
      setErrorBankRouting(true);
    } else {
      setErrorBankRouting(false);
    }
    if (recipientAccountDetails.account_number.length < 6) {
      setErrorBankAccount(true);
    } else {
      setErrorBankAccount(false);
    }
    if (recipientAccountDetails.routing_number.length < 9 || recipientAccountDetails.account_number.length < 6) {
      return false;
    }
    return true;
  };

  function getRecipientIdentifierDisplay() {
    return recipientIdentifier && recipientIdentifier.length > 0
      ? recipientIdentifier
      : externalTransferStore.externalTransferDetails.recipient_identifier;
  }

  function getPaymentTypeDisplay() {
    switch (paymentType) {
      case "paypal": {
        return "PayPal";
      }
      case "venmo": {
        return "Venmo";
      }
      case "pushtocard": {
        return "Direct to Debit Card";
      }
      case "pushtoaccount": {
        return "Direct to Account";
      }
      case "prizeoutcard": {
        return "Prizeout";
      }
      default: {
        return "";
      }
    }
  }

  async function postTransferPayment() {
    try {
      await externalTransferStore.postExternalTransferPayment(
        transferId,
        inviteId,
        paymentType,
        recipientType,
        recipientIdentifier,
      );
      setStatusDate(new Date());
      setViewState("submitted");
    } catch (err) {
      setError(true);
    }
  }

  const submitTransfer = async () => {
    setLoading(true);
    setErrorEmail(false);
    if (confirmIdentifier()) {
      postTransferPayment();
    }
    setLoading(false);
  };

  const deleteTransfer = async () => {
    setLoading(true);
    try {
      await externalTransferStore.deleteExternalTransferPayment(transferId, inviteId);
      setStatusDate(new Date());
      setViewState("declined");
    } catch (err) {
      setError(true);
    }

    setLoading(false);
  };

  const backOutDeleteTransfer = async () => {
    setLoading(false);
    setViewState("default");
  };

  useEffect(() => {
    setLoading(true);
    const load = async () => {
      try {
        await externalTransferStore.loadExternalTransferDetails(transferId, inviteId);
        setStatusDate(new Date(externalTransferStore.externalTransferDetails.transfer_state_date));
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    load();
    // Message port does not work properly for Safari on mobile or desktop, Chrome on mobile
    // or Firefox on mobile. We will run a check and perform different actions for those cases
    const messagePortMalfunction = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (messagePortMalfunction) {
      const interval = setInterval(() => {
        if (
          externalTransferStore.externalTransferDetails.transfer_state !== "Succeeded" &&
          externalTransferStore.externalTransferDetails.transfer_state !== "Failed" &&
          ((document.documentElement.textContent || document.documentElement.innerText).indexOf(
            "Receive with Debit Card",
          ) > -1 ||
            (document.documentElement.textContent || document.documentElement.innerText).indexOf(
              "Payment is processing",
            ) > -1)
        ) {
          load();
        } else if (
          externalTransferStore.externalTransferDetails.transfer_state === "Succeeded" ||
          externalTransferStore.externalTransferDetails.transfer_state === "Failed"
        ) {
          setFrameHeight("200px");
        }
      }, 3000);
      setTimeout(() => {
        clearInterval(interval);
      }, 600000);
      return () => clearInterval(interval);
    }
  }, [externalTransferStore, transferId, inviteId]);

  useEffect(() => {
    setPaymentType(externalTransferStore.externalTransferDetails.payment_type);
    switch (externalTransferStore.externalTransferDetails.transfer_state) {
      case "Failed": {
        setViewState("failed");
        break;
      }
      case "Succeeded": {
        setViewState("submitted");
        break;
      }
      case "WaitForExternalInput": {
        setViewState("default");
        break;
      }
      default: {
        setViewState("processing");
        break;
      }
    }
  }, [externalTransferStore.externalTransferDetails.transfer_state]);

  async function pollTransferDetails() {
    await externalTransferStore.loadExternalTransferDetails(transferId, inviteId);
    if (externalTransferStore.transferCompleted()) {
      return;
    }

    setTimeout(pollTransferDetails, 3000);
  }

  const getIframeUrl = () => {
    const domain = window.location.host;
    if (domain === "localhost:5100") {
      return "http://localhost:3000";
    }
    if (domain === "app.staging.neuralpayments.com") {
      return "https://p2c.app.staging.neuralpayments.com";
    }
    if (domain === "app.neuralpayments.com") {
      return "https://p2c.app.neuralpayments.com";
    }
    if (domain === "np-dev-app-frontend.ngrok.io") {
      return "https://np-dev-app-frontend-cde.ngrok.io";
    }
    return "https://p2c.app.neuralpayments.com";
  };

  const iframeUrl = `${getIframeUrl()}?t=${transferId}&i=${inviteId}&off_network_bg_primary=${fi_ui_customization.off_network_bg_primary.slice(
    1,
    7,
  )}&off_network_bg_secondary=${fi_ui_customization.off_network_bg_secondary.slice(
    1,
    7,
  )}&off_network_button_color=${fi_ui_customization.off_network_button_color.slice(
    1,
    7,
  )}&off_network_text_color=${fi_ui_customization.off_network_text_color.slice(1, 7)}&app=p2c`;

  const TrDetails = () => {
    const note = externalTransferStore.externalTransferDetails.note;
    return (
      <Typography component="div" style={{ padding: "10px 0" }}>
        <Box textAlign={"center"}>
          <b>{externalTransferStore.externalTransferDetails.sender}</b> sent{" "}
          <b>${externalTransferStore.externalTransferDetails.amount}</b>
          <>
            {note && note.length > 0 ? (
              <>
                {" "}
                with the note: <br /> "{note}"
              </>
            ) : (
              <>.</>
            )}
          </>
        </Box>
      </Typography>
    );
  };

  function getFailedStateMsg(): string {
    if (paymentType === "pushtocard") {
      return "Oh no...this card was not accepted at this time, and the funds have been sent back to the sender. Let the sender know that they can resend the funds, and you can select PayPal or Venmo.";
    }

    return `Transfer failed on ${statusDate.toLocaleDateString("en-US")} at ${statusDate.toLocaleTimeString(
      "en-US",
    )}. The payment has been returned to the sender.`;
  }

  const checkIndex = (page: string, index: number) => {
    switch (page) {
      case "homepage":
        return index > 0 && index < 10;
      case "optionspage":
        return index > 9;
    }
  };

  const prizeout_params = {
    env: fi_ui_customization.prizeout_partner?.environment,
    user: {
      session_id: `${transferId}.${inviteId}`,
    },
    publisher: {
      id: fi_ui_customization.prizeout_partner?.partner_id,
    },
    callbacks: {
      onClose: function () {
        externalTransferStore.loadExternalTransferDetails(transferId, inviteId).then(() => {
          console.log("close, details fetched");
        });
      },
      onCashoutFail: function () {
        externalTransferStore.loadExternalTransferDetails(transferId, inviteId).then(() => {
          console.log("fail, details fetched");
        });
      },
      onCashoutSuccess: function () {
        externalTransferStore.loadExternalTransferDetails(transferId, inviteId).then(() => {
          console.log("success, details fetched");
        });
      },
    },
  };

  const clickPayPal = () => {
    setPaymentType("paypal");
    setViewState("confirmPayPal");
  };

  const clickVenmo = () => {
    setPaymentType("venmo");
    if ("email" === guessRecipientType(getRecipientIdentifierDisplay())) {
      setViewState("changeVenmoIdentifier");
    } else {
      setViewState("confirmVenmo");
    }
  };

  const clickP2C = () => {
    setPaymentType("pushtocard");
    setViewState("changePushToCardCard");
  };

  const clickP2A = () => {
    setPaymentType("pushtoaccount");
    setViewState("changePushToAccount");
    setRecipientType("routing_account");
  };

  const clickDecline = async () => {
    setViewState("confirmDecline");
  };

  const clickPrizeout = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.prizeoutSDK.init(prizeout_params);
  };

  const prizeoutEnabled = () => {
    if (
      fi_ui_customization.button_indexes.prizeout_index > 0 &&
      fi_ui_customization.prizeout_partner &&
      parseFloat(externalTransferStore.externalTransferDetails.amount) < 500.01
    ) {
      return true;
    }
  };

  useEffect(() => {
    if (
      prizeoutEnabled() &&
      fi_ui_customization.button_indexes.prizeout_widget &&
      ((viewState === "default" && checkIndex("homepage", fi_ui_customization.button_indexes.prizeout_index)) ||
        (viewState === "moreOptions" && checkIndex("optionspage", fi_ui_customization.button_indexes.prizeout_index)))
    ) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.prizeoutSDK.create_widget(document.getElementById("mini-widget"), prizeout_params);
      }, 200);
    }
  }, [viewState]);

  const renderButtons = (page: string) => {
    const renderButton = (title: string, index: number, onclick: any) => {
      if (checkIndex(page, index)) {
        return (
          <Box textAlign={"center"} style={{ order: index }}>
            <Button variant="outlined" onClick={onclick}>
              <b>{title}</b>
            </Button>
          </Box>
        );
      }
    };

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <>{renderButton("PayPal", fi_ui_customization.button_indexes.paypal_index, clickPayPal)}</>
        <>{renderButton("Venmo", fi_ui_customization.button_indexes.venmo_index, clickVenmo)}</>
        <>{renderButton("Direct to Debit Card", fi_ui_customization.button_indexes.p2c_index, clickP2C)}</>
        <>{renderButton("Direct to Account", fi_ui_customization.button_indexes.p2a_index, clickP2A)}</>
        <>{renderButton("Decline Payment", fi_ui_customization.button_indexes.decline_index, clickDecline)}</>
        {prizeoutEnabled() && checkIndex(page, fi_ui_customization.button_indexes.prizeout_index) && (
          <Box textAlign={"center"} style={{ order: fi_ui_customization.button_indexes.prizeout_index }}>
            {fi_ui_customization.button_indexes.prizeout_widget ? (
              <div id="mini-widget" style={{ ...textFieldStyle, height: "220px" }}></div>
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  clickPrizeout();
                }}
              >
                <b>Gift Card</b>
              </Button>
            )}
          </Box>
        )}
        {page === "homepage" ? (
          <>
            {(fi_ui_customization.button_indexes.paypal_index > 9 ||
              fi_ui_customization.button_indexes.venmo_index > 9 ||
              fi_ui_customization.button_indexes.p2c_index > 9 ||
              fi_ui_customization.button_indexes.p2a_index > 9 ||
              fi_ui_customization.button_indexes.prizeout_index > 9 ||
              fi_ui_customization.button_indexes.decline_index > 9) && (
              <Box textAlign={"center"} style={{ order: 10 }}>
                <Button variant="outlined" onClick={() => setViewState("moreOptions")}>
                  More Options
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Box textAlign={"center"} style={{ order: 20 }}>
            <Button variant="outlined" onClick={returnHome}>
              Go Back
            </Button>
          </Box>
        )}
      </div>
    );
  };

  const renderAdvertising = () => {
    let link = fi_ui_customization.advertising_link;

    if (!fi_ui_customization.advertising_link.includes("http")) {
      link = `https://${fi_ui_customization.advertising_link}`;
    }
    return (
      <>
        {(fi_ui_customization.advertising_link || fi_ui_customization.advertising_text) && (
          <Typography component="div" style={{ padding: "10px 0" }}>
            <Box textAlign={"center"}>
              {fi_ui_customization.advertising_text && <div>{fi_ui_customization.advertising_text}</div>}
              {fi_ui_customization.advertising_link && (
                <a href={link} target="_blank">
                  {fi_ui_customization.advertising_link.length > 40 ? (
                    <>{fi_ui_customization.advertising_link.slice(0, 40)}...</>
                  ) : (
                    <>{fi_ui_customization.advertising_link}</>
                  )}
                </a>
              )}
            </Box>
          </Typography>
        )}
      </>
    );
  };

  const renderP2aInfo = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <b style={{ marginRight: "5px", textAlign: "right" }}>
          <div>Routing Number:</div>
          <div>Account Number:</div>
        </b>
        <div style={{ textAlign: "left" }}>
          <div>{recipientAccountDetails.routing_number}</div>
          {viewState === "submitted" ? (
            <div>{recipientAccountDetails.account_number.replace(/^.{5}/g, "*****")}</div>
          ) : (
            <div>{recipientAccountDetails.account_number}</div>
          )}
        </div>
      </div>
    );
  };

  const renderP2aGoBack = () => {
    return (
      <Box textAlign={"center"}>
        <Button
          variant="outlined"
          onClick={() => {
            setRecipientType("");
            setRecipientIdentifier(defaultRecipientIdentifier);
            setRecipientAccountDetails({ routing_number: "", account_number: "" });
            setErrorBankAccount(false);
            setErrorBankRouting(false);
            returnHome();
          }}
        >
          <b>Go Back</b>
        </Button>
      </Box>
    );
  };

  switch (true) {
    case error:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <main>Sorry, an error has occurred.</main>
          </Grid>
        </div>
      );
    case "submitted" === viewState:
      return (
        <div>
          <TrDetails />
          <Typography component="div" style={{ padding: "10px 0" }}>
            <Box textAlign={"center"}>
              {paymentType !== "pushtoaccount" ? (
                <>
                  Congratulations! Payment was sent to <b>{getRecipientIdentifierDisplay()}</b> via{" "}
                  {getPaymentTypeDisplay()}
                </>
              ) : (
                <>Transfer initiated to {renderP2aInfo()}</>
              )}{" "}
              on {statusDate.toLocaleDateString("en-US")} at {statusDate.toLocaleTimeString("en-US")}
            </Box>
          </Typography>
          {renderAdvertising()}
          <Typography component="div" style={{ padding: "10px 0" }}>
            {paymentType !== "prizeoutcard" && (
              <Box textAlign={"center"}>You may now close this window and check your account for the funds.</Box>
            )}
          </Typography>
        </div>
      );
    case "confirmDecline" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 0" }}>
                <Box textAlign={"center"}>
                  You are choosing to decline payment. The sender will be notifed via their banking app.
                </Box>
              </Typography>
              <Box textAlign={"center"}>
                <Button variant="outlined" onClick={deleteTransfer}>
                  <b>Confirm</b>
                </Button>
              </Box>
              <Box textAlign={"center"}>
                <Button variant="outlined" onClick={backOutDeleteTransfer}>
                  Go Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      );
    case "declined" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 0" }}>
                <Box textAlign={"center"}>
                  Payment was declined on {statusDate.toLocaleDateString("en-US")} at{" "}
                  {statusDate.toLocaleTimeString("en-US")}. Funds will be returned to the sender.
                </Box>
              </Typography>
              {renderAdvertising()}
            </Grid>
          </Grid>
        </div>
      );
    case "failed" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 0" }}>
                <Box textAlign={"center"}>{getFailedStateMsg()}</Box>
              </Typography>
              {renderAdvertising()}
            </Grid>
          </Grid>
        </div>
      );
    case "processing" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 0" }}>
                <Box textAlign={"center"}>Payment is processing...</Box>
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
    case "changePayPalIdentifier" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 10px 50px" }}>
                <Box textAlign={"center"}>
                  Please choose an alternate {getPaymentTypeDisplay()} account that can receive funds.
                </Box>
              </Typography>
              <Box textAlign={"center"}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    data-testid="user_PayPalIdentifier"
                    label="Email or US phone number"
                    value={recipientIdentifier}
                    onChange={handleRecipientIdentifierChange()}
                    style={textFieldStyle}
                    helperText={errorEmail ? "Please enter a valid email or phone number" : ""}
                    error={errorEmail}
                    disabled={loading}
                    InputLabelProps={{
                      style: { color: fi_ui_customization.off_network_text_color },
                    }}
                  />
                </FormControl>
              </Box>
              <Box textAlign={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (confirmIdentifier()) {
                      setViewState("confirmPayPal");
                    }
                  }}
                >
                  <b>Confirm</b>
                </Button>
              </Box>
              <Box textAlign={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setRecipientType("");
                    setRecipientIdentifier("");
                    returnHome();
                  }}
                >
                  <b>Go Back</b>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      );
    case "changeVenmoIdentifier" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 10px 50px" }}>
                <Box textAlign={"center"}>
                  Please choose an alternate {getPaymentTypeDisplay()} account that can receive funds.
                </Box>
              </Typography>
              <Box textAlign={"center"}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    data-testid="user_VenmoIdentifier"
                    label="US phone number"
                    value={recipientIdentifier}
                    onChange={handleRecipientIdentifierChange()}
                    style={textFieldStyle}
                    helperText={errorEmail ? "Please enter a valid phone number" : ""}
                    error={errorEmail}
                    disabled={loading}
                    InputLabelProps={{
                      style: { color: fi_ui_customization.off_network_text_color },
                    }}
                  />
                </FormControl>
              </Box>
              <Box textAlign={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (confirmIdentifier()) {
                      setViewState("confirmVenmo");
                    }
                  }}
                >
                  <b>Confirm</b>
                </Button>
              </Box>
              <Box textAlign={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setRecipientType("");
                    setRecipientIdentifier("");
                    returnHome();
                  }}
                >
                  <b>Go Back</b>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      );
    case "confirmPayPal" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 10px 50px" }}>
                <Box textAlign={"center"}>
                  Please confirm that your {getPaymentTypeDisplay()} account can receive funds sent to{" "}
                  <b>{getRecipientIdentifierDisplay()}</b>.
                </Box>
              </Typography>
              <Box textAlign={"center"}>
                <Button variant="outlined" onClick={submitTransfer}>
                  <b>Send Payment via {getPaymentTypeDisplay()}</b>
                </Button>
              </Box>
              <Box textAlign={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setRecipientType("email");
                    setViewState("changePayPalIdentifier");
                  }}
                >
                  Choose an alternate email <br /> or US phone number
                </Button>
              </Box>
              <Box textAlign={"center"}>
                <Button variant="outlined" onClick={returnHome}>
                  Go Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      );
    case "confirmVenmo" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 10px 50px" }}>
                <Box textAlign={"center"}>
                  Please confirm that your {getPaymentTypeDisplay()} account can receive funds sent <br /> to{" "}
                  <b>{getRecipientIdentifierDisplay()}</b>.
                </Box>
              </Typography>
              <Box textAlign={"center"}>
                <Button variant="outlined" onClick={submitTransfer}>
                  <b>Send Payment via {getPaymentTypeDisplay()}</b>
                </Button>
              </Box>
              <Box textAlign={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setRecipientType("email");
                    setViewState("changeVenmoIdentifier");
                  }}
                >
                  Choose an alternate US phone number
                </Button>
              </Box>
              <Box textAlign={"center"}>
                <Button variant="outlined" onClick={returnHome}>
                  Go Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      );
    case "confirmP2a" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 10px 50px" }}>
                <Box textAlign={"center"}>
                  Please confirm that your {getPaymentTypeDisplay()} can receive funds sent to
                  {renderP2aInfo()}
                </Box>
              </Typography>
              <Box textAlign={"center"}>
                <Button variant="outlined" onClick={submitTransfer}>
                  <b>Send Payment via {getPaymentTypeDisplay()}</b>
                </Button>
              </Box>
              <Box textAlign={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setViewState("changePushToAccount");
                  }}
                >
                  Choose an alternate account
                </Button>
              </Box>
              {renderP2aGoBack()}
            </Grid>
          </Grid>
        </div>
      );
    case "moreOptions" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <>{renderButtons("optionspage")}</>
            </Grid>
          </Grid>
        </div>
      );
    case "changePushToCardCard" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 10px" }}>
                <Box textAlign={"center"}>Receive with Debit Card</Box>
              </Typography>
              <Box textAlign={"center"}>
                <WrappedCardInput
                  onExternalInputSuccessful={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    setTimeout(pollTransferDetails, 3000);
                  }}
                  onCancelP2c={() => setViewState("default")}
                  iframeUrl={iframeUrl}
                  frameHeight={frameHeight}
                  setFrameHeight={setFrameHeight}
                />
              </Box>
              <Box textAlign={"center"}>
                <Button id="go-back-from-p2c" variant="outlined" onClick={returnHome} style={{ marginTop: "0" }}>
                  Go Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      );
    case "changePushToAccount" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 10px" }}>
                <Box textAlign={"center"}>Transfer to Account</Box>
              </Typography>
              <Box textAlign={"center"}>
                <FormControl style={{ width: "100%", margin: "3px auto" }}>
                  <TextField
                    label="Routing Number"
                    id="routing_number"
                    value={recipientAccountDetails.routing_number}
                    onChange={handleRecipientAccountDetailsChange}
                    required
                    type="tel"
                    style={textFieldStyle}
                    helperText={errorBankRouting ? "Please enter a valid Routing Number" : "Required"}
                    error={errorBankRouting}
                    disabled={loading}
                    InputLabelProps={{
                      style: { color: fi_ui_customization.off_network_text_color },
                    }}
                  />
                </FormControl>
              </Box>
              <Box textAlign={"center"}>
                <FormControl style={{ width: "100%", margin: "20px auto 3px auto" }}>
                  <TextField
                    label="Account Number"
                    id="account_number"
                    value={recipientAccountDetails.account_number}
                    onChange={handleRecipientAccountDetailsChange}
                    required
                    type="tel"
                    style={textFieldStyle}
                    helperText={errorBankAccount ? "Please enter a valid Account Number" : "Required"}
                    error={errorBankAccount}
                    disabled={loading}
                    InputLabelProps={{
                      style: { color: fi_ui_customization.off_network_text_color },
                    }}
                  />
                </FormControl>
              </Box>
              <Box textAlign={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (confirmBankAccount()) {
                      setViewState("confirmP2a");
                      setRecipientIdentifier(
                        recipientAccountDetails.routing_number + "_" + recipientAccountDetails.account_number,
                      );
                    }
                  }}
                >
                  <b>Confirm</b>
                </Button>
              </Box>
              {renderP2aGoBack()}
            </Grid>
          </Grid>
        </div>
      );
    case "default" === viewState:
      return (
        <div>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} sm={6}>
              <TrDetails />
              <Typography component="div" style={{ padding: "10px 10px 30px" }}>
                <Box textAlign={"center"}>
                  Payment will be sent to <b>{getRecipientIdentifierDisplay()}</b> via your choice of the service below.
                </Box>
              </Typography>
              <>{renderButtons("homepage")}</>
            </Grid>
          </Grid>
        </div>
      );
    default:
      return <div />;
  }
});

export default TransferWorkflow;
