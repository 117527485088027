import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { FiUiCustomization } from "src/services/generatedApi";
import { fiUiCustomizationApi } from "src/services/api";

export class FiUiCustomizationStore {
  @computed public get fiUiCustomization() {
    return this._details;
  }

  @observable public _details: FiUiCustomization = {
    logo_bg: "",
    logo_height: "",
    logo_width: "",
    logo_url: "",
    off_network_bg_primary: "",
    off_network_bg_secondary: "",
    off_network_button_color: "",
    off_network_text_color: "",
    ribbon_color_1: "",
    ribbon_color_2: "",
    logo_vertical_margin: "",
    advertising_link: "",
    advertising_text: "",
    button_indexes: {
      paypal_index: 1,
      venmo_index: 2,
      p2c_index: 3,
      p2a_index: -1,
      prizeout_index: -1,
      prizeout_widget: false,
      decline_index: 6,
    },
    prizeout_partner: {
      partner_id: "",
      environment: "",
    },
  };

  @action public async loadFiUiCustomization(transferId: string) {
    const response = await fiUiCustomizationApi.getUi(transferId);
    this._details = response.data;
  }
}

const fiUiCustomizationStore = new FiUiCustomizationStore();
export default createContext(fiUiCustomizationStore);
