import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

const heroStyle = {
  maxWidth: "1100px",
  margin: "auto",
  padding: "64px 0",
};

const NotFound = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <div>
          <div style={heroStyle}>
            <Typography style={{ textAlign: "center" }} variant="h5">
              Page Not Found
            </Typography>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default NotFound;
