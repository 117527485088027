import React from "react";
import { observer } from "mobx-react-lite";
import ExternalTransferWorkflow from "./TransferWorkflow";
import GlobalTheme from "src/theme";
import FiUiCustomizationDetails from "../../theme/FiUiCustomizationDetails";

export const Transfer = observer(() => {
  const { fiUiCustomizationDetails } = FiUiCustomizationDetails();

  const { fi_ui_customization } = GlobalTheme();

  const fiUilogoContainer = {
    backgroundColor: fi_ui_customization?.logo_bg,
    paddingTop: `${fi_ui_customization.logo_vertical_margin}px`,
    paddingBottom: `${fi_ui_customization.logo_vertical_margin}px`,
    display: "flex",
    justifyContent: "center",
  };

  const fiUilogoStyles = {
    height: `${fi_ui_customization?.logo_height}px`,
    width: `${fi_ui_customization?.logo_width}px`,
    backgroundImage: `url(${fi_ui_customization?.logo_url})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    margin: "10px",
  };

  const ribbon = {
    height: "6px",
    backgroundImage: `linear-gradient(to right, ${fi_ui_customization.ribbon_color_1}, ${fi_ui_customization.ribbon_color_2})`,
  };

  const FiUiLogoBanner = () => {
    return (
      <>
        <div style={fiUilogoContainer}>
          <div style={fiUilogoStyles}></div>
        </div>
        <div style={ribbon}></div>
      </>
    );
  };

  return (
    <main>
      {fiUiCustomizationDetails && fi_ui_customization.logo_url !== "" && <FiUiLogoBanner />}

      <div style={{ padding: "0 15px" }}>
        <ExternalTransferWorkflow />
      </div>
    </main>
  );
});

export default Transfer;
