import { useContext, useEffect, useState } from "react";
import FiUiCustomizationContext from "src/stores/FiUiCustomizationStore";
import { FiUiCustomization } from "src/services/generatedApi";
import queryString from "query-string";

const FiUiCustomizationDetails = () => {
  const location = window.location.href.split("?")[1];

  const params = queryString.parse(location);
  const transferId = params.t as string;

  const fiUiCustomizationStore = useContext(FiUiCustomizationContext);
  const [fiUiCustomizationDetails, setFiUiCustomizationDetails] = useState<FiUiCustomization | undefined>(undefined);

  useEffect(() => {
    const load = async () => {
      await fiUiCustomizationStore.loadFiUiCustomization(transferId);
      setFiUiCustomizationDetails(fiUiCustomizationStore?._details);
    };
    load();
  }, [fiUiCustomizationStore, transferId]);

  return { fiUiCustomizationDetails };
};

export default FiUiCustomizationDetails;
