import React, { SyntheticEvent, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";

interface IProps {
  onExternalInputSuccessful: () => void;
  onCancelP2c: () => void;
  iframeUrl: string;
  frameHeight: string;
  setFrameHeight: (value: string) => void;
}

export const WrappedCardInput = observer((props: IProps) => {
  const { onExternalInputSuccessful, onCancelP2c, iframeUrl, frameHeight, setFrameHeight } = props;
  const channelRef = useRef<MessageChannel>(new MessageChannel());

  function hideP2CgoBack() {
    const p2cGoBack = document.getElementById("go-back-from-p2c");
    if (p2cGoBack) {
      p2cGoBack.style.display = "none";
    }
  }

  useEffect(() => {
    channelRef.current.port1.onmessage = (e: MessageEvent) => {
      const data = e.data;
      if (data.frameHeight) {
        setFrameHeight(data?.frameHeight);
      }

      if (!data?.event) {
        console.log("Received a message on the messaging channel but no data was set");
        return;
      }

      switch (data.event) {
        case "p2cExternalInputEntered":
          console.log(`Received event ${data.event} on messaging port`);
          onExternalInputSuccessful();
          hideP2CgoBack();
          break;
        case "p2cCancel":
          console.log(`Received event ${data.event} on messaging port`);
          onCancelP2c();
          break;
        default:
          console.log(`Unknown event received from the messaging channel ${data.event}`);
      }
    };
  }, []);

  function onIframeLoad(e: SyntheticEvent<HTMLIFrameElement>) {
    e.currentTarget.contentWindow?.postMessage({ event: "messageChannel" }, "*", [channelRef.current.port2]);
  }

  return (
    <iframe
      title={"debit-card-input-iframe"}
      id="cde-iframe"
      style={{ border: "0" }}
      width={"100%"}
      scrolling={"no"}
      height={frameHeight}
      src={iframeUrl}
      onLoad={onIframeLoad}
      allow=""
    ></iframe>
  );
});

export default WrappedCardInput;
