import { createTheme } from "@mui/material/styles";
import FiUiCustomizationDetails from "./FiUiCustomizationDetails";

const GlobalTheme = () => {
  const { fiUiCustomizationDetails } = FiUiCustomizationDetails();

  let fi_ui_customization = {
    logo_bg: "#ffffff", // white
    logo_height: "0",
    logo_width: "0",
    logo_url: "",
    off_network_bg_primary: "#ffffff", //white
    off_network_bg_secondary: "#eeeeee", //gray
    off_network_button_color: "#000000", //black
    off_network_text_color: "#000000", //black
    ribbon_color_1: "#000000", //black
    ribbon_color_2: "#000000", //black
    logo_vertical_margin: "0",
    advertising_link: "",
    advertising_text: "",
    button_indexes: {
      paypal_index: 1,
      venmo_index: 2,
      p2c_index: 3,
      p2a_index: -1,
      prizeout_index: -1,
      prizeout_widget: false,
      decline_index: 6,
    },
    prizeout_partner: {
      partner_id: "",
      environment: "",
    },
    contrast1: "#ffffff", // white
  };

  const isColor = (str: string) => {
    if (/^#[0-9A-F]{6}$/i.test(str) == true) {
      return true;
    }
  };

  const isString = (str: string) => {
    if (typeof str === "string") {
      return true;
    }
  };

  // if any colors are invalid, use defaults
  if (
    fiUiCustomizationDetails &&
    isColor(fiUiCustomizationDetails?.logo_bg) &&
    isColor(fiUiCustomizationDetails?.off_network_bg_primary) &&
    isColor(fiUiCustomizationDetails?.off_network_bg_secondary) &&
    isColor(fiUiCustomizationDetails?.off_network_button_color) &&
    isColor(fiUiCustomizationDetails?.off_network_text_color) &&
    isColor(fiUiCustomizationDetails?.ribbon_color_1) &&
    isColor(fiUiCustomizationDetails?.ribbon_color_2)
  ) {
    fi_ui_customization = {
      ...fi_ui_customization,
      logo_bg: fiUiCustomizationDetails.logo_bg,
      off_network_bg_primary: fiUiCustomizationDetails.off_network_bg_primary,
      off_network_bg_secondary: fiUiCustomizationDetails.off_network_bg_secondary,
      off_network_button_color: fiUiCustomizationDetails.off_network_button_color,
      off_network_text_color: fiUiCustomizationDetails.off_network_text_color,
      ribbon_color_1: fiUiCustomizationDetails.ribbon_color_1,
      ribbon_color_2: fiUiCustomizationDetails.ribbon_color_2,
    };
  }

  // if any strings are invalid, use defaults
  if (
    fiUiCustomizationDetails &&
    isString(fiUiCustomizationDetails?.logo_url) &&
    isString(fiUiCustomizationDetails?.advertising_link) &&
    isString(fiUiCustomizationDetails?.advertising_text)
  ) {
    fi_ui_customization = {
      ...fi_ui_customization,
      logo_url: fiUiCustomizationDetails.logo_url,
      advertising_link: fiUiCustomizationDetails.advertising_link,
      advertising_text: fiUiCustomizationDetails.advertising_text,
      prizeout_partner: fiUiCustomizationDetails.prizeout_partner,
    };
  }

  // if any integers are invalid, use defaults
  if (
    fiUiCustomizationDetails &&
    Number.isInteger(+fiUiCustomizationDetails.logo_height) &&
    Number.isInteger(+fiUiCustomizationDetails.logo_width) &&
    Number.isInteger(+fiUiCustomizationDetails.logo_vertical_margin)
  ) {
    fi_ui_customization = {
      ...fi_ui_customization,
      logo_height: fiUiCustomizationDetails.logo_height,
      logo_width: fiUiCustomizationDetails.logo_width,
      logo_vertical_margin: fiUiCustomizationDetails.logo_vertical_margin,
    };
  }

  if (
    fiUiCustomizationDetails &&
    Number.isInteger(fiUiCustomizationDetails.button_indexes.paypal_index) &&
    Number.isInteger(fiUiCustomizationDetails.button_indexes.venmo_index) &&
    Number.isInteger(fiUiCustomizationDetails.button_indexes.p2c_index) &&
    Number.isInteger(fiUiCustomizationDetails.button_indexes.p2a_index) &&
    Number.isInteger(fiUiCustomizationDetails.button_indexes.prizeout_index) &&
    Number.isInteger(fiUiCustomizationDetails.button_indexes.decline_index)
  ) {
    fi_ui_customization = {
      ...fi_ui_customization,
      button_indexes: fiUiCustomizationDetails.button_indexes,
    };
  }

  let theme = createTheme({
    palette: {
      primary: {
        main: "#4a95fc",
        light: "#60b6ff",
        dark: "#1d3aab",
        contrastText: "#fff",
      },
      secondary: {
        main: "#000",
        light: "#b2b2b2",
        contrastText: "#fff",
      },
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              backgroundColor: "$labelColor",
              borderRadius: "4px",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            border: "solid",
            borderWidth: "1px",
            textTransform: "none",
            margin: "16px 0",
            padding: "16px 0",
            width: "95%",
            lineHeight: "19px",
          },
        },
      },
    },
  });

  theme = createTheme({
    palette: {
      primary: {
        main: fi_ui_customization.off_network_text_color,
        dark: fi_ui_customization.off_network_bg_secondary,
        contrastText: fi_ui_customization.contrast1,
      },
      secondary: {
        main: fi_ui_customization.off_network_button_color,
      },
      text: {
        primary: fi_ui_customization.off_network_text_color,
      },
      background: {
        default: fi_ui_customization.off_network_bg_primary,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&:disabled": {
              backgroundColor: fi_ui_customization.off_network_bg_primary,
              color: fi_ui_customization.contrast1,
            },
            "&:hover": {
              backgroundColor: fi_ui_customization.off_network_button_color,
              borderColor: fi_ui_customization.off_network_bg_secondary,
              color: fi_ui_customization.off_network_bg_secondary,
            },
            backgroundColor: fi_ui_customization.off_network_bg_secondary,
            border: "solid",
            borderWidth: "1px",
            color: fi_ui_customization.off_network_button_color,
            textTransform: "none",
            margin: "16px 0",
            padding: "16px 0",
            width: "95%",
            lineHeight: "19px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& label.Mui-focused": {
              borderWidth: "2px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: fi_ui_customization.off_network_text_color,
              },
              "&:hover fieldset": {
                borderWidth: "3px",
              },
              "&.Mui-focused fieldset": {
                borderWidth: "2px",
              },
            },
          },
        },
      },
    },
  });

  return { theme, fi_ui_customization };
};
export default GlobalTheme;
