import React from "react";
import { Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import { NotFound, ExternalTransfer } from "src/screens";
import { ROUTE_CONSTANTS } from "src/constants";

const { EXTERNAL_TRANSFER_PATH } = ROUTE_CONSTANTS;

const AppRouter = () => {
  return (
    <div>
      <CssBaseline />
      <Routes>
        <Route path={EXTERNAL_TRANSFER_PATH} element={<ExternalTransfer />} />
        <Route path="/" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default AppRouter;
