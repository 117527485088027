import { action, computed, makeObservable, observable } from "mobx";
import { createContext } from "react";
import { ExternalTransferDetails } from "src/services/generatedApi";
import { externalApi } from "src/services/api";

export class ExternalTransferStore {
  constructor() {
    makeObservable(this);
  }

  @computed public get externalTransferDetails() {
    return this._details;
  }

  // eslint:disable-next-line: variable-name
  @observable private _details: ExternalTransferDetails = {
    sender: "",
    note: "",
    amount: "",
    recipient_type: "",
    recipient_identifier: "",
    payment_type: "",
    transfer_state: "",
    transfer_state_date: "",
  };

  @action public async loadExternalTransferDetails(transferId: string, inviteId: string) {
    const response = await externalApi.getExternalTransfer(transferId, inviteId);
    this._details = response.data;
  }

  @action public async postExternalTransferPayment(
    transferId: string,
    inviteId: string,
    paymentType: string,
    recipientType: string,
    recipientIdentifier: string,
  ) {
    const response = await externalApi.postExternalTransfer(transferId, inviteId, {
      payment_type: paymentType,
      recipient_type: recipientType,
      recipient_identifier: recipientIdentifier,
    });
    return response.data;
  }

  @action public async deleteExternalTransferPayment(transferId: string, inviteId: string) {
    const response = await externalApi.deleteExternalTransfer(transferId, inviteId);
    return response.data;
  }

  @action public clear() {
    this._details = {
      sender: "",
      note: "",
      amount: "",
      recipient_type: "",
      recipient_identifier: "",
      payment_type: "",
      transfer_state: "",
      transfer_state_date: "",
    };
  }

  @action public transferCompleted(): boolean {
    return this._details.transfer_state === "Succeeded" || this._details.transfer_state === "Failed";
  }
}

const externalTransferStore = new ExternalTransferStore();
export default createContext(externalTransferStore);
