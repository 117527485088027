import React, { StrictMode } from "react";
import App from "./components/App";
import { createRoot } from "react-dom/client";

import { configure } from "mobx";

// Ideally, we should enforce the reactivity that mobx recommends.
// But they also recommend that we turn off the enforcement in the
// beginning to help ease with migration.
// https://mobx.js.org/migrating-from-4-or-5.html
configure({ enforceActions: "never" });

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
